import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import auth from "services/auth";
import Spinner from "elements/Spinner";
import AppContext from "contexts/AppContext";
import api from "../services/api";
import "styles/_security_popup.scss";
import Translator, { __ } from "services/Translator";

import { Modal, Button, Alert } from "react-bootstrap";

const SecurityPopup = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [security, setSecurity] = useState(null);
  const [securityPopupModal, setSecurityPopupModal] = useState(false);
  const [securityPopupData, setSecurityPopupData] = useState({
    login: "",
    password: "",
  });

  const [spinner, setSpinner] = useState(false);

  const [securityPopupDataErrors, setSecurityPopupDataErrors] = useState({
    login: "",
    password: "",
  });

  const updateSecurityPopupInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    // updateRegistrationForm(field, value);
    setSecurityPopupData({ ...securityPopupData, [field]: value });
  };

  const securityPopupLogin = async (ev) => {
    ev.preventDefault();
    var valid = validateSecurityPopupForm();
    if (valid) {
      setSpinner(true);
      var response = await api.post("/pre-login", securityPopupData);
      setSpinner(false);
      if (response.status == 200) {
        //Here, no actual user token is provided. So, user is still not logged in
        //However, this temporary token will get past the security popup
        await auth.login(response.data.user);
        setSecurity(false);
      }
    }
  };

  const validateSecurityPopupForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    if (securityPopupData.login.length < 2 || securityPopupData.login.length > 250) {
      errorsTmp = { ...errorsTmp, login: __("Please enter the user name") };
      valid = false;
    }

    if (securityPopupData.password.length < 2 || securityPopupData.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: __("Please enter the password") };
      valid = false;
    }

    setSecurityPopupDataErrors(errorsTmp);

    return valid;
  };

  const ping = async () => {
    var response = await api.get("/ping");
    // console.log("PING", response);
    if (response.status === 200) {
      setSecurity(false);
    } else if (response.status === 403) {
      if (response.data && response.data.showSecurityPopup) {
        var userId = auth.getUserId();
        if (!userId) {
          setSecurity(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      ping();
    } else {
      setSecurity(false);
    }
    const interval = setInterval(() => {
      var authenticated = auth.isAuthenticated();
      if (!authenticated) {
        ping();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const openModal = () => {
    setSecurityPopupModal(true);
  };
  const closeModal = () => {
    setSecurityPopupModal(false);
  };

  return (
    <>
      {spinner && <Spinner />}
      {security === null ? (
        <Spinner />
      ) : security === false ? (
        <>{props.children}</>
      ) : (
        <>
          <div className="security_overlay">
            <div className="security_popup_logo">
              <img src={require("../assets/images/logos/zino-logo-new.svg").default} alt="Coming Soon" />
            </div>

            <a href="#" onClick={openModal} className="security_popup_trigger common_btn ">
              {__("Early Access")}
            </a>
          </div>
          <Modal show={securityPopupModal}>
            <Modal.Body className="login_modal security_popup">
              <div onClick={closeModal} className="modal_close">
                <i className="fal fa-times"></i>
              </div>
              <div className="modal_headline">{__("Maintenance. Back online in 30 minutes…")}</div>
              <form onSubmit={securityPopupLogin}>
                <div className="form_area">
                  <div className="input_item">
                    <div className="name">
                      {__("User Name")} <span className="requried_icon">*</span>
                    </div>
                    <input
                      type="text"
                      name="login"
                      onChange={updateSecurityPopupInput}
                      value={securityPopupData.login}
                      className={"form-control name_value " + (securityPopupDataErrors.login ? "error" : "")}
                    />
                    <div className="errorMsg">{securityPopupDataErrors.login}</div>
                  </div>

                  <div className="input_item">
                    <div className="name">
                      {__("Password")}
                      <span className="requried_icon">*</span>
                    </div>
                    <input
                      type="password"
                      name="password"
                      onChange={updateSecurityPopupInput}
                      value={securityPopupData.password}
                      className={"form-control name_value " + (securityPopupDataErrors.password ? "error" : "")}
                    />
                    <div className="errorMsg">{securityPopupDataErrors.password}</div>
                  </div>
                  <div className="login_button">
                    <Button type="submit" className="login_btn">
                      {__("Login")}
                    </Button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default SecurityPopup;
