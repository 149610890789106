import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import api from "services/api";
import Slider from "react-slick";

export default function Vip(props) {
  const [selectedLanguage, setSelectedLanguage] = useState({});

  const getSelectedLanguage = async () => {
    var lang = await Translator.getSelectedLanguage();
    setSelectedLanguage(lang);
  };

  //Get Banners
  const [banners, setBanners] = useState([]);
  const getBanners = async (ev) => {
    var response = await api.get("/vip-banners");
    setBanners(response.data);
  };

  useEffect(() => {
    getBanners();
    getSelectedLanguage();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
    // slidesToShow: 1,
    // slidesToScroll: 1,
  };

  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "vip",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <div className="landing_banner">
        <Slider {...settings}>
          {banners?.map((banner, index) => (
            <div className="single_banner">
              <img className="banner_image" src={banner.banner} />
              <div className="banner_content_area ">
                <h1 className="banner_title">{banner.title}</h1>
                <div className="line_border d-none">
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
                <div className="banner_sub_text">{banner.sub_title}</div>
                <br />
                {/* <Link
              onClick={() => {
                props.setModalSignUpPropsData(true);
                setTimeout(function () {
                  props.setModalSignUpPropsData(false);
                }, 500);
              }}
              className="common_btn float-left"
            >
              {__("Register Now")}
            </Link> */}

                {props.isAuthenticated && (
                  <Link className="common_btn float-left" to="/games/slots">
                    {__("Play Now!")}
                  </Link>
                )}

                {!props.isAuthenticated && (
                  <Link
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="common_btn float-left"
                  >
                    {__("Register Now")}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="content_page vip_page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {selectedLanguage.code == "es" && (
                <div className="common_headline_with_bg boldText">
                  {__("Loyalty")} {__("and")} {__("Program")} <span className="lime_green_text">{__("VIP")}</span>
                </div>
              )}

              {selectedLanguage.code != "es" && (
                <div className="common_headline_with_bg boldText">
                  {__("Loyalty")} {__("and")} <span className="lime_green_text">{__("VIP")}</span> {__("Program")}
                </div>
              )}

              <div className="common_text">
                {pageContent && renderHTML(pageContent.content_data.content)}
                {/* <p className="paragraph white">
                  {__(
                    "Zino Casino Arab generously rewards loyal players by having a great Loyalty Coins program."
                  )}
                </p>
                <p className="paragraph white">
                  {__(
                    "With each $20 you play in cash wagers on slots, you will receive 1 Loyalty Coin. With your coins you can go the the Loyalty Coins Store and buy great items like Free Spins, Bonus Money, and even Cash Money!"
                  )}
                </p>

                <p className="paragraph white">
                  {__(
                    "Zino Casino Arab also has a great VIP Program. The more money in total you have deposited, the higher your VIP level will become. The higher your VIP level is, the faster you will earn Loyalty Coins because of the Loyalty Coins Accelerator. Read all details in our Bonus Terms."
                  )}
                </p>
                
                <p className="paragraph white">
                  {__(
                    "Then we have 2 exclusive VIP statuses - Platinum and Diamond."
                  )}
                </p>
                <p className="paragraph white">
                  {__("There are two ways to achieve these VIP levels;")}
                </p>
                <p className="paragraph white">
                  {__(
                    "The first is by invitation. You will receive an email notifying you of your status upgrade. It is not possible to apply for this status, however, the players who obtained a High Roller special VIP status after having made a certain amount in deposits, will notified automatically. The higher the total deposited amount, the higher your VIP status will get and each levels means great awards!"
                  )}
                </p> */}
              </div>
            </div>
            <div className="col-md-12">
              <div className="common_headline_with_bg boldText">
                {__("Exclusive")} <span className="lime_green_text">{__("Reward")}.</span>
              </div>
              <ul className="cheap_price">
                <li>
                  <img className="check_mark" src={require("../assets/images/common/check-mark.png").default} alt="" />
                  {__("Reward 1")}
                </li>
                <li>
                  <img className="check_mark" src={require("../assets/images/common/check-mark.png").default} alt="" />
                  {__("Reward 2")}
                </li>
                <li>
                  <img className="check_mark" src={require("../assets/images/common/check-mark.png").default} alt="" />
                  {__("Reward 3")}
                </li>
                <li>
                  <img className="check_mark" src={require("../assets/images/common/check-mark.png").default} alt="" />
                  {__("Reward 4")}
                </li>
                <li>
                  <img className="check_mark" src={require("../assets/images/common/check-mark.png").default} alt="" />
                  {__("Reward 5")}
                </li>
                <li>
                  <img className="check_mark" src={require("../assets/images/common/check-mark.png").default} alt="" />
                  {__("Reward 6")}
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>

      <div className="line_border center_align d-none">
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </>
  );
}
