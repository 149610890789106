import config from "configs/config";
import { lang } from "moment";
import api from "services/api";
import ls from "services/ls";

function currentTimestamp() {
  return parseInt(Date.now() / 1000);
}

class Translator {
  static async getSelectedLanguage() {
    var language = ls.get("language");
    if (!language || language === undefined) {
      var response = await api.get("/ip-details");
      var langCode = config.defaultLanguage;
      if (response.status === 200 && response.data.ip_country) {
        var country = response.data.ip_country;
        if (
          ["AR", "CO", "CL", "EC", "PE", "PY", "UY", "VE", "MX", "CR", "BO", "HN", "PA", "SV", "GT", "NI"].indexOf(
            country
          ) !== -1
        ) {
          langCode = "es";
        } else if (["BR"].indexOf(country) !== -1) {
          langCode = "pt";
        } else if (["SA", "AE", "KW", "QA", "EG", "OM", "JO"].indexOf(country) !== -1) {
          langCode = "ar";
        }
      } else {
        langCode = config.defaultLanguage;
      }
      var response = await api.get("/languages");
      if (response.status === 200 && response.data) {
        for (var lang of response.data) {
          if (lang.code === langCode) {
            Translator.setSelectedLanguage(lang);
            break;
          }
        }
      }
    }
    return language;
  }

  static setSelectedLanguage(language) {
    ls.set("language", language);
    ls.remove("translations");
    Translator.fetchTranslations();
  }

  static async fetchTranslations() {
    var translations = ls.get("translations");
    if (!translations || !translations.lastFetched || currentTimestamp() - translations.lastFetched > 1800) {
      //Translation not yet fetched or expired (30 minutes threshold time)
      var lang = await Translator.getSelectedLanguage();
      var response = await api.get("/translations");
      if (response.status === 200) {
        translations = response.data;
        translations.lastFetched = currentTimestamp();
        ls.set("translations", translations);
        console.log("Translations fetched for language " + lang);
      } else {
        console.log("Error fetching translations for language " + lang);
      }
    }
  }

  static getTranslations() {
    var translations = ls.get("translations");
    return translations;
  }

  static translate(line, params) {
    var translations = Translator.getTranslations();
    if (translations && translations.lines) {
      if (translations.lines[line]) {
        line = translations.lines[line];
      }
      var finalParams = { ...translations.site_variables };
      if (params !== undefined) {
        finalParams = { ...finalParams, ...params };
      }

      for (var item of Object.entries(finalParams)) {
        line = line.replace(":" + item[0], item[1]);
      }
    }

    return line;
  }
}

export default Translator;

export function __(line, params) {
  return Translator.translate(line, params);
}
