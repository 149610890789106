import logo from "./logo.svg";
import "./App.scss";
// import  "dis/bootstrap/less/bootstrap.less";
import React, { useEffect } from "react";
import ls from "local-storage";
import { OpenRoutes, PrivateRoutes } from "./routes/router";
import auth from "services/auth";
import Spinner from "elements/Spinner";
import InactivityChecker from "elements/InactivityChecker";
import AppContext from "contexts/AppContext";
import Translator, { __ } from "services/Translator";
import { Helmet } from "react-helmet";
import SecurityPopup from "elements/SecurityPopup";
import GeoRestriction from "elements/GeoRestriction";

function App(props) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);

  const authResult = new URLSearchParams(window.location.search);
  const afftoken = authResult.get("clickid"); // for integration with affise

  React.useEffect(() => {
    console.log(window.location.search);
    if (afftoken) {
      ls.set("afftoken", afftoken);
    }
    if (window.location.search) {
      ls.set("query_params", window.location.search);
    }
  }, [afftoken, window.location.search]);

  const appContext = React.useMemo(
    () => ({
      updateUserObj: async (data) => {
        await checkLoggedIn();
      },
    }),
    []
  );

  const checkLoggedIn = async () => {
    var authenticated = await auth.isAuthenticated();
    if (authenticated) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const init = async () => {
    await checkLoggedIn();
    await Translator.fetchTranslations();
    // auth.checkLastUsed();
    setInterval(() => {
      // auth.updateLastUsed();
    }, 5000);
    // window.addEventListener('mousemove keypress')
    setIsReady(true);
  };
  React.useEffect(() => {
    init();
  }, []);

  if (isReady) {
    return (
      <>
        <Helmet>
          <title>{__("Zino Casino Arab")}</title>
        </Helmet>
        <AppContext.Provider value={appContext}>
          <GeoRestriction>
            <SecurityPopup>
              {!isLoggedIn && <OpenRoutes {...props} />}
              {isLoggedIn && (
                <React.Fragment>
                  <InactivityChecker />
                  <PrivateRoutes {...props} />
                </React.Fragment>
              )}
            </SecurityPopup>
          </GeoRestriction>
        </AppContext.Provider>
      </>
    );
  } else {
    return <Spinner />;
  }
}

export default App;
