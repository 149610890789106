import React, { useState } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import { __ } from "services/Translator";

export default function GamesFilter(props) {
  // const __ = (text) => text;
  const params = useParams();
  const gamesCategory = params.category;
  return (
    <div className="games_filter">
      <div className="container">
        <div className="menus">
          <ul>
            <li>
              <Link
                className={gamesCategory === "all" ? "active" : ""}
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
                to="/games/all"
              >
                <span className="menu_icon ">
                  <img
                    className="main_icon allGamesIcon"
                    alt={__("All Games")}
                    src={require("../../assets/images/icons/white_icons/bonus_buy.svg").default}
                  />
                  <img
                    className="hover_icon allGamesIcon"
                    alt={__("All Games")}
                    src={require("../../assets/images/icons/golden_icons/bonus_buy.svg").default}
                  />
                </span>
                <span className="menu_text">{__("All Games")}</span>
              </Link>
            </li>
            <li>
              <Link
                className={gamesCategory === "slots" ? "active" : ""}
                to="/games/slots"
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
              >
                <span className="menu_icon">
                  <img
                    className="main_icon slotsIcon "
                    alt={__("Slots")}
                    src={require("../../assets/images/icons/white_icons/slots.svg").default}
                  />
                  <img
                    className="hover_icon slotsIcon "
                    alt={__("Slots")}
                    src={require("../../assets/images/icons/golden_icons/slots.svg").default}
                  />
                </span>
                <span className="menu_text">{__("Slots")}</span>
              </Link>
            </li>
            <li>
              <Link
                className={gamesCategory === "crash-games" ? "active" : ""}
                to="/games/crash-games"
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
              >
                <span className="menu_icon">
                  <img
                    className="main_icon  "
                    alt={__("Crash Games")}
                    src={require("assets/images/icons/crash-white.svg").default}
                  />
                  <img
                    className="hover_icon  "
                    alt={__("Crash Games")}
                    src={require("assets/images/icons/crash-golden.svg").default}
                  />
                </span>
                <span className="menu_text">{__("Crash Games")}</span>
              </Link>
            </li>

            {/* <li>
              <Link
                className={gamesCategory === "provably-fair" ? "active" : ""}
                to="/games/provably-fair"
                onClick={() => {
                  props.setSelectedProviders(['spribe'])
                }}
              >
                <span className="menu_icon provablyFair">
                  <img
                    className="main_icon"
                    alt={__("Provably Fair")}
                    src={
                      require("../../assets/images/icons/white_icons/provably-fair-white.svg")
                        .default
                    }
                  />
                  <img
                    className="hover_icon"
                    alt={__("Provably Fair")}
                    src={
                      require("../../assets/images/icons/golden_icons/provably-fair-light.svg")
                        .default
                    }
                  />
                </span>
                <span className="menu_text">{__("Provably Fair")}</span>
              </Link>
            </li> */}

            <li>
              <Link
                className={gamesCategory === "table-games" ? "active" : ""}
                to="/games/table-games"
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
              >
                <span className="menu_icon">
                  <img
                    className="main_icon tableGames"
                    alt={__("Table Games")}
                    src={require("../../assets/images/icons/white_icons/table-games.svg").default}
                  />
                  <img
                    className="hover_icon tableGames"
                    alt={__("Table Games")}
                    src={require("../../assets/images/icons/golden_icons/table-games.svg").default}
                  />
                </span>
                <span className="menu_text">{__("Table Games")}</span>
              </Link>
            </li>

            <li>
              <Link
                className={
                  gamesCategory === "live-casino" ||
                  gamesCategory === "blackjack" ||
                  gamesCategory === "baccarat" ||
                  gamesCategory === "video-poker" ||
                  gamesCategory === "roulette"
                    ? "active"
                    : ""
                }
                to="/games/live-casino"
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
              >
                <span className="menu_icon ">
                  <img
                    className="main_icon liveCasinoIcon"
                    alt={__("live casino")}
                    src={require("../../assets/images/icons/white_icons/live-casino.svg").default}
                  />
                  <img
                    className="hover_icon liveCasinoIcon"
                    alt={__("live casino")}
                    src={require("../../assets/images/icons/golden_icons/live-casino.svg").default}
                  />
                </span>
                <span className="menu_text">{__("Live Casino")}</span>
              </Link>
            </li>

            {/* <li>
              <Link
                className={gamesCategory === "jackpot" ? "active" : ""}
                to="/games/jackpot"
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
              >
                <span className="menu_icon">
                  <img
                    className="main_icon jackpotIcon"
                    alt={__("Jackpots")}
                    src={require("../../assets/images/icons/white_icons/jackpot.svg").default}
                  />
                  <img
                    className="hover_icon jackpotIcon"
                    alt={__("Jackpots")}
                    src={require("../../assets/images/icons/golden_icons/jackpot.svg").default}
                  />
                </span>
                <span className="menu_text">{__("Jackpots")}</span>
              </Link>
            </li> */}

            <li>
              <Link
                className={gamesCategory === "new" ? "active" : ""}
                to="/games/new"
                onClick={() => {
                  props.setSelectedProviders([]);
                }}
              >
                <span className="menu_icon ">
                  <img
                    className="main_icon newGamesIcon"
                    alt={__("New Games")}
                    src={require("../../assets/images/icons/white_icons/new-games.svg").default}
                  />
                  <img
                    className="hover_icon newGamesIcon"
                    alt={__("New Games")}
                    src={require("../../assets/images/icons/golden_icons/new-games.svg").default}
                  />
                </span>
                <span className="menu_text">{__("New")}</span>
              </Link>
            </li>

            {/* <li>
              <Link
                className={gamesCategory === "megaways" ? "active" : ""}
                to="/games/megaways"
                onClick={() => {
                  props.setSelectedProviders([])
                }}
              >
                <span className="menu_icon">
                  <img
                    className="main_icon"
                    alt={__("Megaways")}
                    src={
                      require("../../assets/images/icons/white_icons/megaways.svg")
                        .default
                    }
                  />
                  <img
                    className="hover_icon"
                    alt={__("Megaways")}
                    src={
                      require("../../assets/images/icons/golden_icons/megaways.svg")
                        .default
                    }
                  />
                </span>
                <span className="menu_text">{__("Megaways")}</span>
              </Link>
            </li>
            
            

            <li>
              <Link
                className={gamesCategory === "bonus-buy" ? "active" : ""}
                to="/games/bonus-buy"
                onClick={() => {
                  props.setSelectedProviders([])
                }}
              >
                <span className="menu_icon">
                  <img
                    className="main_icon"
                    alt={__("Bonus Buy")}
                    src={
                      require("../../assets/images/icons/white_icons/bonus_buy.svg")
                        .default
                    }
                  />
                  <img
                    className="hover_icon"
                    alt={__("Bonus Buy")}
                    src={
                      require("../../assets/images/icons/golden_icons/bonus_buy.svg")
                        .default
                    }
                  />
                </span>
                <span className="menu_text">{__("Bonus Buy")}</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
