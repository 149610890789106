import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Dropdown, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import AppContext from "contexts/AppContext";
import auth from "services/auth";
import { __ } from "services/Translator";

export default function LeftSidebar(props) {
  const params = useParams();
  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const tab = params.tab;
  const [showSidebar, setShowSidebar] = useState(false);
  // const [showSidebar, setShowSidebar] = useState(props.showLeftSidebar);
  const [showPreSidebar, setShowPreSidebar] = useState(false);
  //   alert(props.openLeftSidebar);
  // const showSidebar = props.showLeftSidebar;
  // const setShowSidebar = props.setShowLeftSidebar;

  const logout = async () => {
    await auth.logout();
    await updateUserObj();
    setIsAuthenticated(auth.isAuthenticated);
  };

  useEffect(() => {
    // if (props.showLeftSidebar) {
    //   setShowSidebar(props.showLeftSidebar);
    // }
    if (props.showPreLeftSidebar) {
      setShowPreSidebar(true);
    } else {
      setShowPreSidebar(false);
    }
  }, [props]);

  return (
    <>
      <div className={"sidebar_container right_sidebar   " + (showPreSidebar ? "leftAccountSidebar" : "")}>
        <div className={"sidebar_area   " + (showSidebar ? "show_bar" : "")}>
          <div className="button_close">
            <Link
              className="closeIcon"
              to="#"
              onClick={() => {
                setShowSidebar(false);
              }}
            >
              <i className="fas fa-times"></i>
            </Link>
          </div>

          <div className="left_side">
            <div className="logo">
              <Link to="/">
                <img src={require("../assets/images/logos/zino-logo-new.svg").default} />
              </Link>
            </div>
            {/* <div className="catalog_button">
            <Link
              onClick={() => {
                setShowSidebar(false);
              }}
              className="common_btn no_margin"
              to="/games/all"
            >
              Go to catalog
            </Link>
          </div> */}

            <div className="left_sidebar_menus">
              <ul>
                {props.isAuthenticated && (
                  <>
                    <li>
                      <Link
                        to="/dashboard/account-details"
                        className={tab === "account-details" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                          <path d="M637 161.1l-19.1-19.2c-4-4.1-10.6-4.1-14.6 0L500.2 245.6l-47.4-47.7c-4-4.1-10.6-4.1-14.6 0L419 217.1c-4 4.1-4 10.6 0 14.7l73.8 74.3c4 4.1 10.6 4.1 14.6 0L637 175.8c4-4 4-10.6 0-14.7zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96zm89.6 256c-28.8 0-42.4 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464z" />
                        </svg>
                        <span className="menuText">{__("Account Details")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dashboard/cashier"
                        className={tab === "cashier" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zm0 464H48V208h352v256zm0-304H48V48h352v112zM108.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm192 96h38.4c6.4 0 12.8-6.4 12.8-12.8V268.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm96-96h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm0 96h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8z" />
                        </svg>
                        <span className="menuText">
                          {__("Cashier")}{" "}
                          <Badge className="theme_bg radius_25">
                            {props.userBalance.currency}
                            {props.userBalance.total_balance}
                          </Badge>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dashboard/transaction-history"
                        className={tab === "transaction-history" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M128 152v-32c0-4.4 3.6-8 8-8h208c4.4 0 8 3.6 8 8v32c0 4.4-3.6 8-8 8H136c-4.4 0-8-3.6-8-8zm8 88h208c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8H136c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8zm299.1 159.7c-4.2 13-4.2 51.6 0 64.6 7.3 1.4 12.9 7.9 12.9 15.7v16c0 8.8-7.2 16-16 16H80c-44.2 0-80-35.8-80-80V80C0 35.8 35.8 0 80 0h352c8.8 0 16 7.2 16 16v368c0 7.8-5.5 14.2-12.9 15.7zm-41.1.3H80c-17.6 0-32 14.4-32 32 0 17.7 14.3 32 32 32h314c-2.7-17.3-2.7-46.7 0-64zm6-352H80c-17.7 0-32 14.3-32 32v278.7c9.8-4.3 20.6-6.7 32-6.7h320V48z" />
                        </svg>
                        <span className="menuText">{__("Transaction History")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={tab === "gaming-history" ? "active" : ""}
                        to="/dashboard/gaming-history"
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                          <path d="M370.5 138.9l-50.2-7.3-22.5-45.5c-4-8.1-15.7-8.2-19.7 0l-22.5 45.5-50.2 7.3c-9 1.3-12.6 12.4-6.1 18.8l36.3 35.4-8.6 50c-1.5 8.9 7.9 15.8 16 11.6l44.9-23.6 44.9 23.6c8 4.2 17.5-2.6 16-11.6l-8.6-50 36.3-35.4c6.7-6.4 3-17.5-6-18.8zm-60.3 44.4l5.2 30.6-27.4-14.4-27.5 14.4 5.2-30.6-22.2-21.6 30.7-4.5 13.7-27.8 13.7 27.8 30.7 4.5-22.1 21.6zM448 64V12c0-6.6-5.4-12-12-12H140c-6.6 0-12 5.4-12 12v52H12C5.4 64 0 69.4 0 76v61.6C0 199.7 68.1 272 160.7 285.7c29.4 60.7 73.7 90.3 111.3 96.9V480h-86c-14.4 0-26 11.7-26 26.1 0 3.3 2.7 5.9 6 5.9h244c3.3 0 6-2.6 6-5.9 0-14.4-11.6-26.1-26-26.1h-86v-97.4c37.7-6.6 81.9-36.2 111.3-96.9C508 272 576 199.6 576 137.6V76c0-6.6-5.4-12-12-12H448zM32 137.6V96h96v24c0 51.8 7 94.9 18.5 130.2C77.9 232.5 32 178 32 137.6zM288 352c-72 0-128-104-128-232V32h256v88c0 128-56 232-128 232zm256-214.4c0 40.4-46 94.9-114.5 112.6C441 214.9 448 171.8 448 120V96h96v41.6z" />
                        </svg>
                        <span className="menuText">{__("Gaming History")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dashboard/free-spins"
                        className={tab === "free-spins" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M256 84c-94.99 0-172 77.01-172 172s77.01 172 172 172 172-77.01 172-172S350.99 84 256 84zm0 48c18.58 0 36.05 4.4 51.89 11.75l-26.66 36.7c-7.97-2.66-16.35-4.45-25.22-4.45s-17.25 1.79-25.22 4.45l-26.66-36.7C219.95 136.4 237.42 132 256 132zM133.47 270.56c-.58-4.84-1.47-9.58-1.47-14.56 0-32.48 12.83-61.85 33.34-83.98l26.55 36.55C182.03 221.87 176 238.17 176 256c0 .25.07.47.07.72l-42.6 13.84zM232 377.57c-36.13-7.12-66.23-30.21-83.72-61.35l42.71-13.88c9.96 13.94 24.31 24.31 41.01 29.59v45.64zM256 288c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm24 89.57v-45.64c16.7-5.28 31.04-15.64 41.01-29.59l42.71 13.88c-17.49 31.15-47.59 54.23-83.72 61.35zm55.93-120.85c0-.25.07-.47.07-.72 0-17.83-6.03-34.13-15.89-47.43l26.55-36.55C367.17 194.15 380 223.52 380 256c0 4.99-.9 9.73-1.47 14.56l-42.6-13.84zM256 0C114.62 0 0 114.62 0 256s114.62 256 256 256 256-114.62 256-256S397.38 0 256 0zm0 464c-114.69 0-208-93.31-208-208S141.31 48 256 48s208 93.31 208 208-93.31 208-208 208z" />
                        </svg>
                        <span className="menuText">{__("Free Spins")}</span>
                      </Link>
                    </li>

                    {/*<li>
                      <Link
                        to="/dashboard/notifications"
                        className={tab === "notifications" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg
                          height="22"
                          width="22"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z" />
                        </svg>
                        <span className="menuText">{__("Notifications")}</span>
                      </Link>
                      </li>*/}
                    <li>
                      <Link
                        to="/dashboard/messages"
                        className={tab === "messages" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                          <path d="M512 160h-96V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v160c0 35.3 28.7 64 64 64h32v52c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4l76.9-43.5V384c0 35.3 28.7 64 64 64h96l108.9 61.6c2.2 1.6 4.7 2.4 7.1 2.4 6.2 0 12-4.9 12-12v-52h32c35.3 0 64-28.7 64-64V224c0-35.3-28.7-64-64-64zM96 240H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v160c0 8.8-7.2 16-16 16H211.4l-11 6.2-56.4 31.9V240H96zm432 144c0 8.8-7.2 16-16 16h-80v38.1l-56.4-31.9-11-6.2H256c-8.8 0-16-7.2-16-16v-96h112c35.3 0 64-28.7 64-64v-16h96c8.8 0 16 7.2 16 16v160z" />
                        </svg>
                        <span className="menuText">{__("Messages")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dashboard/responsible-gaming"
                        className={tab === "responsible-gaming" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M446.34 433.21l-62.35-137.6c4.44-11.43 8.32-14.17 22.34-28.19a44.715 44.715 0 0 0 11.57-43.18c-8.29-30.95-8.3-26.65 0-57.62a44.721 44.721 0 0 0-11.57-43.18c-22.68-22.7-20.52-18.94-28.82-49.92a44.68 44.68 0 0 0-31.61-31.61c-30.96-8.29-27.22-6.13-49.9-28.81a44.714 44.714 0 0 0-43.19-11.58c-30.87 8.27-26.69 8.29-57.62 0A44.72 44.72 0 0 0 152 13.1c-22.66 22.66-18.93 20.51-49.9 28.81a44.68 44.68 0 0 0-31.61 31.61c-8.29 30.96-6.13 27.22-28.81 49.9-11.29 11.29-15.71 27.76-11.57 43.18 8.29 30.95 8.3 26.65 0 57.62a44.715 44.715 0 0 0 11.57 43.18c15.1 15.11 18.02 17.06 22.34 28.19L1.66 433.21c-5.96 13.15 4.85 27.44 20.45 27.44.29 0 .59-.01.88-.02l72.86-2.51 50.13 47.65C150.45 510 156.26 512 162 512c8.53 0 16.92-4.39 20.55-12.4L224 408.13l41.45 91.47c3.63 8.01 12.02 12.4 20.55 12.4 5.75 0 11.56-2 16.01-6.23l50.13-47.65 72.86 2.51c.3.01.59.02.88.02 15.6-.01 26.42-14.29 20.46-27.44zM153.73 446.9l-39.4-37.44-49.99 1.72 29.72-65.59c2.59 1.28 5.18 2.57 8.04 3.34 25.14 6.74 26.79 5.7 43.06 21.97 8.63 8.63 20.07 13.1 31.63 13.1 1.95 0 3.87-.55 5.81-.8l-28.87 63.7zm23.55-111.76c-22.02-22.08-33.74-24.8-60.92-32.09-11.34-42.3-17.04-45.88-39.4-68.24 11.51-42.93 7.89-49.38 0-78.79 30.96-30.96 31.22-37.69 39.41-68.24 29.09-7.78 37.07-8.22 68.25-39.4 42.62 11.42 49.19 7.94 78.79 0 21.29 21.29 25.65 27.98 68.24 39.4 11.34 42.3 17.04 45.88 39.4 68.25-11.33 42.3-8.19 48.26 0 78.81-21.29 21.29-27.98 25.66-39.4 68.25-26.27 7.04-38.28 9.44-60.93 32.09-31.14-18.18-67.02-15.45-93.44-.04zm176.51 75.01l-20.12-.69-39.4 37.44-28.87-63.7c1.94.26 3.86.8 5.81.8 11.55 0 23-4.47 31.63-13.1 16.41-16.41 17.81-15.2 43.06-21.97 2.85-.76 5.44-2.06 8.04-3.34l29.72 65.58-29.87-1.02zM320 192c0-53.02-42.98-96-96-96s-96 42.98-96 96 42.98 96 96 96 96-42.98 96-96zm-96 48c-26.47 0-48-21.53-48-48s21.53-48 48-48 48 21.53 48 48-21.53 48-48 48z" />
                        </svg>
                        <span className="menuText">{__("Responsible Gaming")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dashboard/verify"
                        className={tab === "Verify" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M446.34 433.21l-62.35-137.6c4.44-11.43 8.32-14.17 22.34-28.19a44.715 44.715 0 0 0 11.57-43.18c-8.29-30.95-8.3-26.65 0-57.62a44.721 44.721 0 0 0-11.57-43.18c-22.68-22.7-20.52-18.94-28.82-49.92a44.68 44.68 0 0 0-31.61-31.61c-30.96-8.29-27.22-6.13-49.9-28.81a44.714 44.714 0 0 0-43.19-11.58c-30.87 8.27-26.69 8.29-57.62 0A44.72 44.72 0 0 0 152 13.1c-22.66 22.66-18.93 20.51-49.9 28.81a44.68 44.68 0 0 0-31.61 31.61c-8.29 30.96-6.13 27.22-28.81 49.9-11.29 11.29-15.71 27.76-11.57 43.18 8.29 30.95 8.3 26.65 0 57.62a44.715 44.715 0 0 0 11.57 43.18c15.1 15.11 18.02 17.06 22.34 28.19L1.66 433.21c-5.96 13.15 4.85 27.44 20.45 27.44.29 0 .59-.01.88-.02l72.86-2.51 50.13 47.65C150.45 510 156.26 512 162 512c8.53 0 16.92-4.39 20.55-12.4L224 408.13l41.45 91.47c3.63 8.01 12.02 12.4 20.55 12.4 5.75 0 11.56-2 16.01-6.23l50.13-47.65 72.86 2.51c.3.01.59.02.88.02 15.6-.01 26.42-14.29 20.46-27.44zM153.73 446.9l-39.4-37.44-49.99 1.72 29.72-65.59c2.59 1.28 5.18 2.57 8.04 3.34 25.14 6.74 26.79 5.7 43.06 21.97 8.63 8.63 20.07 13.1 31.63 13.1 1.95 0 3.87-.55 5.81-.8l-28.87 63.7zm23.55-111.76c-22.02-22.08-33.74-24.8-60.92-32.09-11.34-42.3-17.04-45.88-39.4-68.24 11.51-42.93 7.89-49.38 0-78.79 30.96-30.96 31.22-37.69 39.41-68.24 29.09-7.78 37.07-8.22 68.25-39.4 42.62 11.42 49.19 7.94 78.79 0 21.29 21.29 25.65 27.98 68.24 39.4 11.34 42.3 17.04 45.88 39.4 68.25-11.33 42.3-8.19 48.26 0 78.81-21.29 21.29-27.98 25.66-39.4 68.25-26.27 7.04-38.28 9.44-60.93 32.09-31.14-18.18-67.02-15.45-93.44-.04zm176.51 75.01l-20.12-.69-39.4 37.44-28.87-63.7c1.94.26 3.86.8 5.81.8 11.55 0 23-4.47 31.63-13.1 16.41-16.41 17.81-15.2 43.06-21.97 2.85-.76 5.44-2.06 8.04-3.34l29.72 65.58-29.87-1.02zM320 192c0-53.02-42.98-96-96-96s-96 42.98-96 96 42.98 96 96 96 96-42.98 96-96zm-96 48c-26.47 0-48-21.53-48-48s21.53-48 48-48 48 21.53 48 48-21.53 48-48 48z" />
                        </svg>
                        <span className="menuText">{__("Verify")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dashboard/account-activity"
                        className={tab === "account-activity" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36z" />
                        </svg>
                        <span className="menuText">{__("Account Activity")}</span>
                      </Link>
                    </li>
                    <li className="my_games_menu d-none">
                      <Link
                        to="/dashboard/my-games"
                        className={tab === "my-games" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <img src={require("../assets/images/icons/heart-svgrepo-com.svg").default} />
                        <span className="menuText">{__("My Games")}</span>
                      </Link>
                    </li>
                    <li className="refer_and_earn_menu">
                      <Link
                        to="/dashboard/refer-and-earn"
                        className={tab === "refer-and-earn" ? "active" : ""}
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                      >
                        <img src={require("../assets/images/icons/refer-and-earn.svg").default} />
                        {/* <i className="fas fa-user-friends"></i> */}

                        <span className="menuText">{__("Refer and Earn")}</span>
                      </Link>
                    </li>
                    <hr className="common_hr"></hr>
                  </>
                )}

                <li className="pormotionMenu">
                  <Link
                    to="/promotions"
                    className="page_item logout_btn"
                    onClick={() => {
                      setShowSidebar(false);
                    }}
                  >
                    {/* <i className="fal fa-power-off"></i> */}

                    <img src={require("../assets/images/icons/promotion-icon.svg").default} />
                    <span className="menuText">{__("Promotions")}</span>
                  </Link>
                </li>
                <li className="vipMenu">
                  <Link
                    to="/vip"
                    className="page_item logout_btn"
                    onClick={() => {
                      setShowSidebar(false);
                    }}
                  >
                    {/* <i className="fal fa-power-off"></i> */}

                    <img src={require("../assets/images/icons/vip-lounge-icon.svg").default} />
                    <span className="menuText">{__("VIP Lounge")}</span>
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={() => {
                      props.setSupportSidebarOpen(true);
                      props.setIsChatVisible(false);
                      setShowSidebar(false);
                    }}
                    to="#"
                    // className="page_item"
                  >
                    <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z" />
                    </svg>
                    <span className="menuText">{__("Help")}</span>
                  </Link>
                </li>

                {props.isAuthenticated && (
                  <>
                    <hr className="common_hr"></hr>
                    <li className="logoutMenu">
                      <Link onClick={logout} className="page_item logout_btn">
                        {/* <i className="fal fa-power-off"></i> */}

                        <img src={require("../assets/images/icons/log-off-icon.svg").default} />
                        <span className="menuText">
                          {""} {__("Logout")}
                        </span>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>

            <br />

            <br />
            {/* <div className="rotate_text">{__("Casino Bull")}</div> */}
          </div>
        </div>
      </div>
      {showSidebar && <div className="invisible_overlay" onClick={() => setShowSidebar(false)}></div>}
    </>
  );
}
